
import SourceFlowHead from "@sourceflow-uk/sourceflow-head";
import metaObject from "../../../.sourceflow/metadata.json";
import { NextSeo } from 'next-seo';
import Script from 'next/script'

export default function SeoHead({
    site_name = "Energy Recruitment Agency",
    page_name = "Energy Recruitment Agency | EarthStream",
    description = "EarthStream is able to source and select top talent across all energy sectors globally including Renewables and Power Generation",
    canonical_link = "https://www.earthstreamglobal.com",
    og_image = "https://cdn.sourceflow.co.uk/y8e8x7v4q0zs7xoaz5agjbmpru1o",
    og_img_alt = "Energy Recruitment Agency",
    robots = ""
}) {
    return (
        <>
            <SourceFlowHead
                metaObject={metaObject}
                title={page_name}
                description={description}
                additionalMetaTags={[{
                    property: 'og:site_name',
                    content: site_name
                }, {
                    property: 'title',
                    content: page_name
                },
                ]}
                images={[
                    {
                        url: og_image,
                        width: 1200,
                        height: 600,
                        alt: og_img_alt,
                        type: "image/jpg",
                    },
                ]}
                robots={robots}
                canonical={canonical_link}
            />
        </>
    )
}
