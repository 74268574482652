import { BreadcrumbNavigation, Header } from "@/components";
import SeoHead from "@/components/SeoHead";
import { getRoute } from "@/functions/getRoute";

export default function ErrorPage() {
  return (
    <div>
      <SeoHead
        page_name={`Page Not Found · EarthStream`}
        canonical_link={`https://www.earthstreamglobal.com/404`}
      />
      <BreadcrumbNavigation items={[{ label: "404" }]} />
      <Header
        back={{
          path: `page.home.component.Header.back`,
          placeholder: `Back to Home`,
          href: getRoute("home"),
        }}
        title={{
          path: `page.error.title`,
          placeholder: "404 - Page Not Found",
        }}
      />
    </div>
  );
}
